.description{
    padding: 20px 0;
}

.description h2{
    line-height: 1.3;
}
@media(max-width: 1080px){
    .description h2{
        line-height: 1.5;
    }
}

.description h3,
.separator-section{
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.2vh;
}

