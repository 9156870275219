.accordion-item{
    border: none!important;
}

.accordion-button{
    color: #fff;
    background-color: #232324;
    border: none;
}
.accordion-body{
    background-color: #232324!important;
}
.accordion-body p{
    color: #fff!important;
}
.card-prod{
    margin: 0 auto!important;
    padding: 0!important;
}
.card-prod img{
    width: 100%;
}
@media (min-width: 992px){
    .col-lg-4 {
        flex: 0 0 auto;
        width: 30%!important;
    }
}