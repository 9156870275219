header{
    background-image: url('https://ucarecdn.com/9c6bf65f-ae76-46a6-8a1e-2a0c5950f6ef/01260520.webp');
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: #000;
    position: relative;
}
@media(max-width: 1080px){
    header{
        background-attachment: local;
    }
}
.header{
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(229, 229, 229, 0.7) 45%, rgba(229, 229, 229, 0.7) 60%, rgba(229, 229, 229, 0.7) 70%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

header h1{
    font-size: 130px;
    width: 90%;
    line-height: 100%;
}
header h1 span{
    position: relative;
    left: -24px;
    display: block; 
    line-height: 130%;
}
header h2{
    font-size: 25px;
    width: 80%;
    line-height: 110%;
    margin-left: 10px;
    margin-bottom: 30px;
}
@media(max-width: 1080px){
    body{
        overflow-x: hidden;
    }
    header h1{
        font-size: 75px;
    }
    header h1 span{
        position: relative;
        left: -15px;
    }
    header h2{
        font-size: 15px;
        width: 70%;
        font-weight: 400;
        margin-left: 0;
    }
    header .p-relative{
        left: 20px;
        top: 15px;
        height: auto;
    }
    header .btn-video{
        height: 55px;
        margin-left: 20px;
    }
    .form-hero form{
        padding-top: 10vh;
    }
}