@import url('https://fonts.cdnfonts.com/css/gotham');

/**
  font-family: 'Gotham', sans-serif;
  font-family: 'Gotham Black', sans-serif;
  font-family: 'Gotham Light', sans-serif;
  font-family: 'Gotham Thin', sans-serif;
  font-family: 'Gotham XLight', sans-serif;
  font-family: 'Gotham Book', sans-serif;
  font-family: 'Gotham Ultra', sans-serif;                                              
*/


* {
  margin: 0;
}

*, *:focus, *:hover {
  outline: none;
}

html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #c59797;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E5E5E5;
}

h1, h2, h3, h4, h5, h6, a, span, textarea {
  font-family: 'Gotham', sans-serif;
  margin: 0;
  font-weight: 300;
}

nav a {
  font-size: 15px;
  transition: .3s all ease-in-out;
}

nav a:hover {
  color: #E8B8B8;
}

.burguer {
  display: flex;
  justify-content: flex-end;
}

.nav-desktop {
  background-color: #e5e5e5;
  padding: 0 20px;
}

.nav-desktop .btn-translate {
  border: none;
  background: transparent;
  color: #000;
  font-family: 'Gotham', sans-serif;
  font-weight: 600;
}

.offcanvas-header {
  position: relative;
}

.btn-close {
  position: absolute;
  right: 30px;
}

@media(min-width: 1080px) {
  .offcanvas {
    width: 60vw !important;
    padding: 0 0 0 5vw;
  }
}

.offcanvas-body .btn-translate {
  color: #fff;
  padding: 0;
}

.offcanvas-body a {
  font-size: 3vh;
}

@media(max-width: 1080px) {
  .offcanvas-body a {
    font-size: 25px;
    line-height: 35px;
  }

  .offcanvas-body p {
    font-size: 12px !important;
  }

  .offcanvas {
    width: 70vw !important;
  }

  .offcanvas-header img {
    width: 70%;
  }

  .phones p, .phones a {
    font-size: 15px;
  }
}

.offcanvas-body hr,
.offcanvas-body h4,
.offcanvas-body li,
.offcanvas-body p {
  color: #fff;
}

.btn-close {
  color: #fff;
  background-color: #E8B8B8;
  opacity: 1;
}

@media(max-width: 1080px) {
  .hide-menu {
    display: none;
  }

  .nav-desktop {
    padding: 0 10px;
  }
}

p, li, th, td, input {
  font-family: 'Gotham Light', sans-serif;
  line-height: 200%;
  margin: 0;
}


body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

/*

h4 = p ===> 20px
*/

h2 {
  font-size: 50px;
}

p, button, li {
  font-size: 20px;
}

p {
  text-align: justify;
}

@media(max-width: 1080px) {
  h2 {
    font-size: 25px;
  }

  p, button, li {
    font-size: 18px;
    line-height: 25px;
  }
}

a {
  color: #000;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
}

a:hover {
  color: #E8B8B8;
}

.nav-desktop .nav-link {
  font-weight: 900;
  font-size: 15px;
}

.dropdown-menu {
  border-radius: 5px;
  border: none;
  background-color: #0008;
}

.dropdown-menu a {
  color: #fff;
}

.nav-link-burger {
  font-weight: 100 !important;
}

/* Globals */
.p-relative {
  position: relative;
}

.p-ttl-sticky {
  font-family: 'Gotham', sans-serif;
  font-weight: 900;
  position: sticky;
  top: 0;
  font-size: 20px;
  padding-right: 70px;
  text-transform: uppercase;
}

@media(max-width: 1080px) {
  .p-ttl-sticky {
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.bg-separator {
  background-color: #212121;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-separator h4 {
  font-size: 6.5vh;
  text-transform: uppercase;
  line-height: 1.5;
}

.talks h2 {
  line-height: 1.5;
}

@media(max-width: 1080px) {
  .bg-separator h4 {
    font-size: 45px;
  }
}

.grid-images img {
  margin: 5px 0;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.certificated h3 {
  font-size: 6vh;
  text-transform: uppercase;
}

@media(max-width: 1080px) {
  .certificated h3 {
    font-size: 40px;
  }
}

.bg-watch {
  height: 100vh;
  background-image: url('https://ucarecdn.com/1c06b8d6-d74c-4865-8f41-5be67ae6dc05/convert_1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.certificated {
  height: 100vh;
  background-image: url('https://ucarecdn.com/88e017c9-177d-4243-80b0-7495d168c3bb/charlas.webp');
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: 50vw 0;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.bg-certificated {
  height: 100vh;
  display: flex;
  align-items: center;
}

@media(max-width: 1080px) {
  .certificated {
    background-position: -60vw 0;
  }

  .bg-certificated {
    background-color: #0007;
  }
}

.quote-marie {
  height: 100vh;
  background-image: url('https://ucarecdn.com/140e2346-1d53-46d5-a0ac-6f8030266a06/organizando_la_casa_casa_haus_el_mueble.webp');
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.bg-quote-education {
  height: 100vh;
  background-image: url('https://ucarecdn.com/3a4fb57e-8bd7-436f-96d0-8fe1d5e8059f/charla3.webp');
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: 50vw 0;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.bg-filter-education {
  height: 100vh;
  display: flex;
  align-items: center;
}

@media(max-width:1080px) {
  .bg-quote-education {
    background-position: center;
  }

  .bg-filter-education {
    background-color: #0006;
  }
}

.bg-mision {
  height: 100vh;
  display: flex;
  align-items: center;
}

.bg-mision h2 {
  line-height: 1.5;
}

@media(max-width: 1080px) {
  .bg-mision {
    background-color: #fff8;
  }
}

.quote-levels {
  height: 100vh;
  background-image: url('https://ucarecdn.com/adc4c2b4-6dd7-4332-88dc-cea2b5242742/Rectangle22.svg');
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  display: flex;
  align-items: center;
}

.bg-levels {
  height: 100vh;
  display: flex;
  align-items: center;
}

@media(max-width: 1080px) {
  .bg-watch {
    background-size: cover;
    background-attachment: inherit;
    background-position: left;
  }

  .bg-mision {
    background-size: cover;
    background-attachment: unset;
  }

  .bg-mision h2,
  .bg-mision p {
    color: #212529;
  }

  .bg-mision h2 {
    font-size: 9vw;
  }

  .bg-mision p {
    font-size: 4vw !important;
  }

  .col-12 {
    flex: 0 0 auto;
    width: 90%;
  }

  .bg-levels {
    background-color: #0007;
  }
}

.border-b {
  border-bottom: 1px solid #000;
}

/* Button */
.accordion-button:focus {
  box-shadow: none;
}

.aws-btn .aws-btn__content {
  padding: 0 30px;
  min-width: 210px;
}

.aws-btn .aws-btn__content>span:nth-child(1)>svg {
  height: auto;
  width: 25px;
  margin-left: 10px;
}

.aws-btn--primary .aws-btn__content {
  font-size: 20px;
  background-color: #E8B8B8;
}

.aws-btn--primary .aws-btn__wrapper:before {
  background-color: #c59797;
}

.aws-btn--primary .aws-btn__wrapper:hover .aws-btn__content {
  background-color: #c59797;
}

/* Accordion */
.accordion-button {
  background-color: #E5E5E5;
  border: none;
  border-bottom: 1px solid #5e5e5e;
}

.accordion-button::after {
  background-color: #E8B8B8;
  background-position: center;
  background-size: 20px;
  padding: 20px;
  border-radius: 5px;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #E8B8B8;
}

.accordion-button:not(.collapsed)::after {
  background-image: #000;
}

.accordion-body {
  background: #f5f5f5;
}

.accordion-body p {
  color: #5e5e5e;
}

/* Table */
.ctd thead tr th,
.pnt thead tr th {
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

@media(max-width: 1080px) {

  .ctd thead tr th,
  .pnt thead tr th {
    font-size: 10px;
  }

  th {
    font-size: 12px
  }
}

.ctd thead tr th:nth-child(2) {
  background-color: #69a41b;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
}

.ctd thead tr th:nth-child(3) {
  background-color: #272727;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
}

.ctd thead tr th:nth-child(4) {
  background-color: #f9d26b;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
}

.ctd tbody tr td,
.pnt tbody tr td {
  text-align: center;
}

.ctd tbody tr td:nth-child(2),
.ctd tbody tr td:nth-child(3),
.ctd tbody tr td:nth-child(4),
.pnt tbody tr td:nth-child(2),
.pnt tbody tr td:nth-child(3),
.pnt tbody tr td:nth-child(4) {
  background: #fff;
  border-bottom: 1px solid black;
}

tbody tr th {
  border-bottom: 1px solid black;
}

.pnt thead tr th:nth-child(2),
.pnt thead tr th:nth-child(3),
.pnt thead tr th:nth-child(4) {
  color: #fff;
  background: #212121;
  box-shadow: 0px 2px 4px rgba(171, 190, 209, 0.6);
}

@media(max-width: 1079px) {

  .styles_container__2pRR5,
  .styles_wrapper__3KXDn {
    width: 100% !important;
  }
}

.levels img {
  filter: grayscale(1) brightness(0.5);
  transition: .2s all ease-in-out;
}

.levels img:hover {
  filter: grayscale(0) brightness(1) drop-shadow(2px 4px 6px black);

}

.icon-wpp {
  filter: invert(1);
  width: auto;
}

.icon-wpp,
.icon-phone {
  height: 40px;
  width: auto;
}


footer .btn-translate {
  border: none;
  color: #fff;
  background: transparent;
  font-family: 'Gotham';
}

.btn-translate:disabled {
  color: gray;
}

.icon-phone-footer {
  height: 40px;
}

.icon-white {
  filter: invert(1);
}

/* go Top */
.toTop {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 80px;
  padding: 20px;
  color: #fff;
  background: rgba(00, 00, 00, 0.3);
  border: none;
  opacity: 1;
  transition: all .3s ease-in-out;
}

.toTop:hover {
  color: #E8B8B8;
}

/* swiper */
.swiper-button-next:after, .swiper-button-prev:after {
  color: #000 !important;
  background-color: #E8B8B8;
  padding: 10px;
  font-size: 15px;
  font-weight: 900;
  border-radius: 5px;
}

/* Form hero */
.form-hero {
  margin-top: auto;
}

.form-hero form {
  display: flex;
  flex-direction: column;
}

.m-form {
  margin: 0 5px 5px 0;
}

.m-form:nth-child(2) {
  margin: 0;
}

form small {
  color: red;
}

.form-hero form label {
  display: none;
}

.thnks-msg{
  font-weight: 400;
  text-align: center;
  font-size: 25px;
  background-color: #ffffff63;
  padding: 30px 0;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.form-hero form input,
.form-hero form textarea {
  background: #30303080;
  border-radius: 4px;
  padding: 16px 20px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  border: none;
  transition: .2s all ease-in-out;
}

.form-hero form input::placeholder,
.form-hero form textarea::placeholder {
  color: #fff;
}

@media(max-width: 1080px) {
  .form-hero form input, .form-hero form textarea {
    width: 95%;
  }
}

.form-hero form input:focus {
  border: 2px solid #E8B8B8 !important;
  box-shadow: 2px 2px #E8B8B8 !important;
}

.form-hero form input[type=submit],
.btn {
  font-family: 'Gotham';
  background: #E8B8B8;
  color: #fff;
  text-transform: uppercase;
}

.btn {
  width: 60%;
  text-align: center;
}

.dropdown-burguer {
  background: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 3vh;
  font-weight: 100;
  padding: 0;
  margin: 0;
}

.list-who-can-buy li::before {
  content: '•';
  margin-right: 10px;
  font-size: 15px;
}

.text-justify {
  text-align: justify;
}


.form-hero form button,
.input-becas form button,
.form-footer form button{
  width: 100%;
  height: 80px;
  border: none;
  outline: none;
  background: #E8B8B8;
  color: #fff;
  font-size: 22px;
  border-radius: 40px;
  text-align: center;
  box-shadow: 0 6px 20px -5px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.check-box{
  width: 80px;
  height: 80px;
  border-radius: 40px;
  box-shadow: 0 0 12px -2px rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  right: -40px;
  opacity: 0;
}

.check-box svg{
  width: 40px;
  margin: 20px;
}

svg path{
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
}

#btnText{
  text-align: center;
}

.active{
  background: #2f2f2f!important;
  transition: 1s;
}

.active .check-box{
  right: 0;
  opacity: 1;
  transition: 1s;
}

.active p{
  margin-right: 125px;
  transition: 1s;
}

.active svg path{
  stroke-dashoffset: 0;
  transition: 1s;
  transition-delay: 1s;
}