
.video-container-styles{
    display: flex;
    height: 400px;
}

.playlist-queue-styles{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}
.video-styles{
    width: 60%;
}
.thumbnail-styles{
    width: 100%;
    height: auto;
    transition: .3s all ease-in-out;
}
.thumbnail-styles:hover{
    background-color: #2121212e;
    cursor: pointer;
    color: #fff;
}

@media(max-width: 1079px){
    .video-container-styles{
        flex-direction: column;
        height: 400px;
    }
    .video-styles{
        width: 100%;
    }
}