.testimonial .card-services{
    display: flex;
    min-height: 260px;
}

.testimonial .card-services-content{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
}

.testimonial .card-services img{
    padding: 5px;
    object-fit: cover;
    height: 300px;
    width: 250px;
}

.testimonial .card-services h3{
    font-size: 2.5vh;
    font-weight: 900;
    margin: 5px 0;
}

.testimonial .card-services h4{
    font-size: 20px;
    text-align: justify;
}

.testimonial .card-services-content p{
    font-size: 20px;
    text-align: start;
    margin-bottom: 0;
    line-height: 25px;
}
.card-home img{
    width: 100%;
}
.card-home .aws-btn .aws-btn__content {
    padding: 0 10px 0 0;
    min-width: 50px;
}

@media(max-width: 1080px){
    .testimonial .card-services{
        flex-direction: column;
    }
    .testimonial .card-services img{
        width: 100%;
        height: 400px;
        object-position: top;
    }
    .swiper{
        padding: 30px 0;
    }
}