.swiper{
    padding: 30px 0;
}
.card-services{
    filter: drop-shadow(0px 6px 12px rgba(171, 190, 209, 0.3));
    background: #fff;
    transition: all .3s ease-in-out;
}
.card-services:hover{
    box-shadow: 0 8px 8px rgba(0,0,0,0.16), 0 8px 8px rgba(0,0,0,0.23);
}

.card-services img{
    width: 15vw;
    height: auto;
}
.card-services-content{
    padding: 25px 15px;
    display: flex;
    justify-content: space-between;
}

.card-services h4{
    font-size: 18px;
}

.card-services h3{
    font-size: 20px;
    font-weight: 900;
}
.card-planner .aws-btn .aws-btn__content,
.card-prod .aws-btn .aws-btn__content{
    padding: 0 10px 0 0;
    min-width: 40px;
}