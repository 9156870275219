.form-footer iframe {
    width: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.input-flex {
    display: flex;
    font-family: 'Gotham', sans-serif;
}

.input-flex label {
    font-weight: 900;
    width: 120px;
    text-transform: uppercase;
}

.form-footer form input,
.form-footer form textarea {
    background: #EEF5FC;
    border-radius: 4px;
    padding: 16px 20px;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
    border: none;
    transition: .2s all ease-in-out;
    margin: 3px;
}

@media(min-width: 1081px){
    .form-footer form textarea {
        width: 112%!important;
    }
}

.form-footer form input:focus {
    border: 2px solid #E8B8B8 !important;
    box-shadow: 2px 2px #E8B8B8 !important;
}

.form-footer form input[type=submit] {
    background: #E8B8B8;
    color: #fff;
    margin-left: 110px;
    width: 40%;
}

@media(max-width: 1080px){
    .input-flex{
        margin: 10px auto;
        flex-direction: column;
    }
    .input-flex label {
        width: 130px;
    }
    .form-footer form textarea {
        width: 100%;
    }
    .form-footer form input[type=submit]{
        width: 60%;
        margin-left: 40px;
        margin-right: auto;
    }
}

footer {
    background-color: #000;
    color: #fff;
    padding: 40px 0;
    display: flex;
    align-items: center;
    margin-top: 100px;
}

footer li a{
    color: #fff;
    font-weight: 100;
}

@media(max-width: 1080px) {
    footer {
        padding: 50px 10px;
    }
}

.b-bottom {
    border-bottom: 1px solid #fff;
}

.btn-whatsapp {
    background-color: #30303080;
    border-radius: 5px;
    color: #fff;
    transition: .3s all ease-in-out;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10000;
}

.btn-whatsapp .btn-alert-wpp{
    position: relative;
    display: flex;
    align-items: center;
}
.btn-whatsapp .btn-alert-wpp span{
    position: absolute;
    top: -3px;
    left: -3px;
    background-color: red;
    color: #fff;
    height: 10px;
    width: 9px;
    line-height: 105%;
    border-radius: 10px;
    font-size: 17px;
}

.btn-whatsapp img {
    width: 50px;
    height: 50px;
}

.btn-whatsapp p{
    display: none;
}

@media(min-width: 1080px){
    .btn-whatsapp p{
        padding: 0 10px 0 10px;
        display: block;
    }
}

.img-socials img {
    height: 35px;
    width: auto;
    transition: .3s all ease-in-out;
}

.img-socials img:hover {
    background-color: #E8B8B8;
    border-radius: 5px;
    cursor: pointer;
}

.nav-footer a {
    color: #fff;
    transition: .3s all ease-in-out;
}

.nav-link-footer{
    font-weight: 900;
}

.nav-footer a:hover {
    color: #E8B8B8;
}

.btn-whatsapp:hover,
.img-socials img:hover {
    transform: scale(1.1);
}