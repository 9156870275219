.search-box {
    background: #212121;
    height: 40px;
    border-radius: 40px;
    padding: 10px;
    position: relative;
}

.search-btn {
    color: #E8B8B8;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #2F3640;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.4s;
    position: absolute;
    top: 0;
    right: 0;
}

.search-txt {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: #fff;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 100%;
    position: absolute;
    top: 0;
}
@media(min-width: 1081px){
    .col-lg-3 {
        flex: 0 0 auto;
        width: 24%!important;
    }
}
.card-planner{
    padding: 0!important;
}
.card-planner img{
    width: 100%;
}